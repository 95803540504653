(function(){
	'use strict';

	// Search HomesByAngelaPena.com
	$('#searchForm').submit(function (e) {
		e.preventDefault();
		var search = $('#search').val().replace(' ', '%20');
		var url = 'http://homesbyangelapena.com/search/#!/location_search_field:' + search + '/';

		window.open(url, '_blank');
	});

	$('.hide-filter').on('click', function () {
		event.preventDefault();
		$('#sidebar').toggleClass('col-md-3 col-md-pull-9 col-md-1 col-md-pull-11 collapsed');
		$('#main-content').toggleClass('col-md-9 col-md-push-3 col-md-11 col-md-push-1');
		$('.filter, .collapse-btn').toggleClass('hide');

		$('.eq-height').matchHeight(); 
	});

	$('a.filter-scroll').smoothScroll({
		offset: -15,
		speed: 800
	});
})();