(function(){
	'use strict';

	// Initialize Equal Height Plugin
	$('.eq-height').matchHeight(); 


	$('a.scroll-top').smoothScroll({
		speed: 400
	});

	$(window).scroll(function () {
		if ($(this).scrollTop() > 200) $('.scroll-top').addClass('show');
		else $('.scroll-top').removeClass('show');
	});


	// Init clipboard
	var clipboard = new ClipboardJS('.btn-copy');
	
	
	clipboard.on('success', function(e) {
		// console.info('Action:', e.action);
		// console.info('Text:', e.text);
		// console.info('Trigger:', e.trigger);
		$(e.trigger).removeClass('clicked');
		$(e.trigger).addClass('clicked');
		setTimeout(function () {
			$(e.trigger).removeClass('clicked');
		}, 3000)
	
		e.clearSelection();
	});
	
	clipboard.on('error', function(e) {
		console.error('Action:', e.action);
		console.error('Trigger:', e.trigger);
	});
})();