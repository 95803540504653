(function () {
	'use strict';
	var $headerBottom = $('#header-bottom');
	var $headerTop = $('#header-top');
	var $headerTopHeight = $headerTop.outerHeight(true);
	var $headerBtmHeight = $headerBottom.outerHeight(true);
	var scrollStart = 0;

	// Scroll Function
	$(window).scroll(function () {		
		var scrollTop = $(window).scrollTop();		

		if (scrollTop < scrollStart) {
			if (scrollTop > $headerTopHeight) $headerBottom.get(0).className = 'fixed moving';
			else $headerBottom.get(0).className = '';
		}
		else if (scrollTop === 0 || scrollTop < $headerTopHeight + $headerBtmHeight) $headerBottom.get(0).className = '';
		else $headerBottom.get(0).className = 'moving';

		return scrollStart = scrollTop;
	});

	// reset values on window resize
	$(window).resize(function () {
		$headerBottom = $('#header-bottom');
		$headerTop = $('#header-top');
		$headerTopHeight = $headerTop.outerHeight(true);
		$headerBtmHeight = $headerBottom.outerHeight(true);
		scrollStart = 0;
	});
 })();