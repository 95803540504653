(function(){
	$('#listingForm-form, #contact-form').submit(function(event) {
		event.preventDefault();

		var postUrl = 'sproutForms/entries/saveEntry';
		var formData = $(this).serialize();

		$.ajax({
			type : 'POST',
			url : postUrl,
			data : formData,
			dataType : 'json',
			encode : true
		})
		.done(function(data) {
			if (data.success == true) {
				console.log('success'); 
				console.log(data); // {success: true}

				$('.form.visible').addClass('hidden');
				$('#thank-you').addClass('show').removeClass('hidden')
				
			} else {
				console.log('error');
				console.log(data);
			}
		});
	});
})();