(function(){
	/////////////////////////////////
	//		Listing Sliders		   //
	/////////////////////////////////

	$('.slider, .testimonial-slider').on('init breakpoint', function () {
		init($(this));
	});

	function init(self) {
		$(self).css('display', 'block');
		$('.slick-next:before, .slick-prev:before').remove();
		$('.slick-next').html('<i class="fa fa-arrow-right"></i>');
		$('.slick-prev').html('<i class="fa fa-arrow-left"></i>');
	}

	// Slider settings
	$('.slider').slick({
		centerMode: true,
		centerPadding: '0px',
		slidesToShow: 3,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					adaptiveHeight: true,
					arrows: false,
					dots: true,
					appendDots: $('.dots'),
					customPaging: function (slider, pageIndex) {
						//you can reference the slider being instantiated as slider.$slider
						return $('<button><i class="fa fa-circle"></i></button').text(slider.$slider.data('buttonlabel'));
					},
				}
			}
		]
	});

	var prevHeight;
	var nextHeight;

	$('.testimonial-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		prevHeight = $('.vegas-wrapper .container').outerHeight(true);
	});

	$('.testimonial-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
		nextHeight = $('.vegas-wrapper .container').outerHeight(true);
		resize(nextHeight);
	});

	$(window).resize(function() {
		resize();
	});

	function resize(height) {
		if (!height) height = $('#testimonials .container').outerHeight(true);
		$('#testimonials').css({
			'height': height
		});
	}
})();