(function () {
	'use strict';

	var $mobileNav = $('#mobile-nav');
	var $mobileNavBurger = $('.mobile-nav-burger');
	var scrollStart;


	// Set mobile nav height
	function setMobileHeight() {
		var height = window.screen.availHeight ? window.screen.availHeight : $(window).height();
		$mobileNav.css('height', height);
	}


	// When clicking hamburger for mobile nav
	$mobileNavBurger.on('click', function () {
		setMobileHeight();

		$mobileNavBurger.each(function () {
			$(this).toggleClass('is-active');
		});	

		if ($(this).hasClass('is-active') === true) {
			scrollStart = $(window).scrollTop();
			$mobileNav.addClass('is-active');
			$(window).on('scroll', scrollMobileNav);
		}
		
		else $mobileNav.removeClass('is-active');
	});

	
	$(window).resize(function () {
		setMobileHeight();
	});


	function scrollMobileNav() {
		var current = $(window).scrollTop();

		if (current - scrollStart >= 150 || current - scrollStart <= -150) {
			$mobileNav.removeClass('is-active');

			$mobileNavBurger.each(function () {
				$(this).removeClass('is-active');
			});

			setTimeout(function () {
				$(window).off('scroll', scrollMobileNav);
			});
		}
	}
})();